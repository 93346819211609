import { filesToStudiesForUpload } from '../../lib/filesToStudies';
import { DICOM_FILETYPE } from '../constants';

export const extractStudyMetadataFromPaths = async filesWithMime => {
  // dicom以外とdicomに分離
  const notDcmFiles = filesWithMime.filter(
    file => file.filetype != DICOM_FILETYPE
  );
  const dcmFiles = filesWithMime.filter(
    file => file.filetype == DICOM_FILETYPE
  );
  const studies = await filesToStudiesForUpload(dcmFiles);
  return [studies, dcmFiles, notDcmFiles];
};
