import FileType from 'file-type/browser';
import { DICOM_FILETYPE, DICOMDIR_FILETYPE } from '../constants';

export const addMimeTypeToFiles = async selectedFiles => {
  /*
   ファイルタイプ を取得して加える
  */
  for (let i = 0; i < selectedFiles.length; i++) {
    const file = selectedFiles[i];
    const filetype = await FileType.fromStream(file.stream());
    if (filetype) {
      selectedFiles[i].filetype = filetype.mime;
    } else {
      selectedFiles[i].filetype = '';
    }
  }
  // DICOMDIRのfiletypeを変更
  for (let i = 0; i < selectedFiles.length; i++) {
    if (selectedFiles[i].path.split('/').pop() === 'DICOMDIR') {
      if (selectedFiles[i].filetype === DICOM_FILETYPE) {
        selectedFiles[i].filetype = DICOMDIR_FILETYPE;
      }
    }
  }
  return selectedFiles;
};
