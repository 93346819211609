import axios from 'axios';
import { removeOrgLastUrl } from '../utils/orgLastUrl';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '@ohif/viewer/src/utils/localStorageKeys';

// this base url will be change based on
// if you need to point to production.
const debugMode = process.env.NODE_ENV !== 'production';
const BASE_URL = debugMode ? 'http://127.0.0.1:8000' : '';

// This request is used when we do NOT have login credentials
// e.g. password reset, user signup
let tokenRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

const setAccessToken = accessToken => {
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);
};

const setRefreshToken = accessToken => {
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);
};

const SignupUser = (username, email, password1, password2) => {
  const signupBody = {
    username: username,
    email: email,
    password1: password1,
    password2: password2,
  };
  return tokenRequest
    .post(`/api/dj-rest-auth/registration/`, signupBody)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

const ResetPassword = email => {
  const resetBody = {
    email: email,
  };
  return tokenRequest
    .post(`/api/dj-rest-auth/password/reset/`, resetBody)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

const ResendEmailAPI = email => {
  const resetBody = {
    email: email,
  };
  return tokenRequest
    .post(`/api/dj-rest-auth/registration/resend-email/`, resetBody)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

const ResetPasswordConfirm = (new_password1, new_password2, uid, token) => {
  const resetBody = {
    new_password1: new_password1,
    new_password2: new_password2,
    uid: uid,
    token: token,
  };
  return tokenRequest
    .post(`/api/dj-rest-auth/password/reset/confirm/`, resetBody)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

const redirectToLogin = () => {

  console.log('REDIRECT LOGIN');
  let loginUrl = '/login';
  // for electron app
  // 前回ログインしてリフレッシュトークンの期限切れの場合はパラメータが消えてしまわないようにする
  const url = new URL(location.href);
  const params = url.searchParams();
  const fromParam = params.get('from');
  if (fromParam !== null) {
    loginUrl += `?from=${fromParam}`;
  }
  // location.href = loginUrl;
  history.push(loginUrl);
}

// Refresh access token in case of error
const errorInterceptor = error => {
  const isBadReqest = status => {
    return status === 400;
  };
  const isCorrectRefreshError = status => {
    return status === 401;
  };
  const isPermissionDenied = status => {
    return status === 403;
  };
  const isNotFound = status => {
    return status === 404;
  };
  const isServerBusy = status => {
    return status >= 500;
  };
  console.debug('error.response', error.response);
  if (error.response) {
    const originalRequest = error.config;
    const status = error.response.status;
    const refreshTokenValue = window.localStorage.getItem(REFRESH_TOKEN);
    if (isCorrectRefreshError(status)) {
      if (error.response.data['detail'] === 'User not found') {
        logoutUser().then();
        return Promise.reject(error);
      } else if (refreshTokenValue) {
        console.log('Get new access token');
        return refreshToken()
          .then(data => {
            // リフレッシュトークンの期限内→リフレッシュして再実行
            const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
            if (accessToken) {
              const headerAuthorization = `Bearer ${accessToken}`;
              authRequest.defaults.headers[
                'Authorization'
              ] = headerAuthorization;
              originalRequest.headers['Authorization'] = headerAuthorization;
            }
            return authRequest(originalRequest);
          })
          .catch(error => {
            // リフレッシュトークンの期限外→ログインページへ
            // if token refresh fails, logout the user to avoid potential security risks.
            logoutUser().then();
            console.log('Refresh token expired');
            console.log(error);

            let loginUrl = '/login';
            // Use continue URL for conference page, medical search
            if (location.pathname.startsWith('/conference')) {
              loginUrl += `?continueUrl=${location.pathname}`;
            } else if (location.pathname.startsWith('/medical-search')) {
              loginUrl += `?continueUrl=/medical-search`;
            }

            // for electron app
            // 前回ログインしてリフレッシュトークンの期限切れの場合はパラメータが消えてしまわないようにする
            console.log('Current URL', location.href);
            // const url = new URL(location.href);
            // const params = url.searchParams();
            const params = new URLSearchParams(location.search);
            console.log('searchParams', params);
            const fromParam = params.get('from');
            if (fromParam !== null) {
              loginUrl += `?from=${fromParam}`;
            }
            location.href = loginUrl;
            // history.push(loginUrl);
          });
      }

    } else if (isBadReqest(status)) {
      if (error.response && error.response.data['detail']) {
        toast.error(
          'リクエストに問題があります。' + error.response.data['detail']
        );
      } else {
        toast.error('リクエストに問題があります。');
      }
    } else if (isPermissionDenied(status)) {
      // toast.error('アクセス権限がありません');
      // 権限がない場合は他のデータも全て表示させたくないので、別のエラー処理が必要
    } else if (isNotFound(status)) {
      toast.error('データが存在しません');
    } else if (isServerBusy(status)) {
      toast.error('サーバーでエラーが発生しています');
    } else {
      toast.error('接続エラーが発生しました');
    }
  }
  return Promise.reject(error);
};

// const createAuthRequest = () => {
//   const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
//   console.log(`AuthRequest accessToken "${accessToken}"`);
//   const authRequest = axios.create({
//     baseURL: BASE_URL,
//     timeout: 60 * 1000,
//     headers: {
//       Authorization: accessToken ? `Bearer ${accessToken}` : null,
//       'Content-Type': 'application/json',
//     },
//   });
//   authRequest.interceptors.response.use(
//     response => response, // this is for all successful requests.
//     error => {
//       // Handle the error. Retry original request with new access token
//       return errorInterceptor(error);
//     }
//   );
//   return authRequest;
// };

// let authRequest = createAuthRequest();


// This is a reusable axios request object that requires a logged-in user with a valid JWT access token.
// The access token is retrieved from browser localStorage each time this is used.
const authRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Get a fresh access token with every request
authRequest.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

authRequest.interceptors.response.use(
  response => response, // this is for all successful requests.
  error => {
    // Handle the error. Retry original request with new access token
    return errorInterceptor(error);
  }
);

const loginUser = (email, password) => {
  const loginBody = { email: email, password: password };
  return tokenRequest
    .post(`/api/dj-rest-auth/login/`, loginBody)
    .then(response => {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access_token);
      window.localStorage.setItem(REFRESH_TOKEN, response.data.refresh_token);
      console.log('LOGGED IN direct:', response.data.user.email, response.data.user.username);
      window.localStorage.setItem('email', response.data.user.email);
      window.localStorage.setItem('username', response.data.user.username);
      // authRequest = createAuthRequest();
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

const loginUserGoogle = accessToken => {
  const loginBody = { access_token: accessToken };
  return tokenRequest
    .post(`/api/dj-rest-auth/google/`, loginBody)
    .then(response => {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access_token);
      window.localStorage.setItem(REFRESH_TOKEN, response.data.refresh_token);
      console.log('LOGGED IN Google:', response.data.user.email, response.data.user.username);
      window.localStorage.setItem('email', response.data.user.email);
      window.localStorage.setItem('username', response.data.user.username);
      // authRequest = createAuthRequest();
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

const refreshToken = () => {
  const refreshBody = { refresh: window.localStorage.getItem(REFRESH_TOKEN) };
  return tokenRequest
    .post(`/api/dj-rest-auth/token/refresh/`, refreshBody)
    .then(response => {
      console.debug(response);
      window.localStorage.setItem(ACCESS_TOKEN, `${response.data.access}`);
      // authRequest = createAuthRequest();
      return Promise.resolve(response.data);
    });
};

const getUserInfo = async () => {
  let userInfo;
  try {
    const response = await authRequest.get('/api/dj-rest-auth/user/');
    userInfo = response.data;
  } catch {
    console.warn('Cannot get user info, login expired?');
    userInfo = {};
  }
  console.debug('userInfo', userInfo);
  return userInfo;
};

const userLoggedIn = async () => {
  const userInfo = await getUserInfo();
  return (userInfo && Object.keys(userInfo).length > 0);
}

const logoutUser = async () => {
  // removeOrgLastUrl();
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  window.localStorage.removeItem('email');
  window.localStorage.removeItem('username');
  // This does not work across tabs!
  authRequest.defaults.headers['Authorization'] = '';
};

export {
  tokenRequest,
  getUserInfo,
  userLoggedIn,
  loginUser,
  loginUserGoogle,
  logoutUser,
  SignupUser,
  ResetPassword,
  ResetPasswordConfirm,
  ResendEmailAPI,
  refreshToken,
  authRequest,
  errorInterceptor,
  BASE_URL,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
};
