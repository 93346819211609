/**
 * Component to display a summary of uploaded data, after the upload
 * is complete.
 */

import React from 'react';
import {
  Button, Dialog, Typography, Modal,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: theme.spacing(2),
  },
  paper: {
    position: 'relative',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '0px',
    borderRadius: '6px',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 3),
  },
  button: {
    margin: theme.spacing(1),
  },
  paragraph: {
    paddingTop: theme.spacing(1),
  }
}));

const UploadConfirmationModal = ({ open, handleClose, uploadedStudies }) => {
  const classes = useStyles();
  const title = 'アップロードされたデータの確認';

  // if (uploadedStudies.length > 0) {
  if (Object.keys(uploadedStudies).length) {
    console.log('UploadConfirmationModal', uploadedStudies);
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
        {/* シリーズ数、インスタンス数が正しいなら、 */}
          <Typography>
            以下のデータはアップロードされました。各スタディがビューアに正しく表示されていることを確認してください。
          </Typography>
          {Object.keys(uploadedStudies).map((key, index) => {
            const study = uploadedStudies[key];
            return (
              <div key={index}>
                <Typography className={classes.paragraph}>
                  スタディ: {study.name}
                </Typography>
                <Typography>
                  モダリティ: {study.modalities}
                </Typography>
                <Typography>
                  シリーズ数: {study.numSeries}
                </Typography>
                <Typography>
                  インスタンス数: {study.numInstances}
                </Typography>
                <Button
                  className={classes.button}
                  href={study.url}
                  target="_blank"
                  color="primary"
                  variant="contained"
                >ビューワで開く</Button>
              </div>
            );
          })}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained">
              閉じる
          </Button>

        </DialogActions>
      </div>

    </Modal>
  );
}

UploadConfirmationModal.defaultProps = {
  uploadedStudies: [],
}

export default UploadConfirmationModal;
