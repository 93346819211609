import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: props => props.sizeRate * 60,
    height: props => props.sizeRate * 30,
    borderRadius: 4,
    backgroundColor: theme.color.bgColorDark,
    margin: theme.spacing(0, 0),
    // fontSize: theme.font.fontSizeLarge,
  },
}));

function OrgAvatar({ orgName, size = 'medium' }) {
  const sizeRate = size === 'small' ? 0.7 : size === 'medium' ? 1.0 : 1.3;
  const classes = useStyles({ sizeRate });

  return (
    <>
      {orgName ? (
        <Avatar variant="square" className={classes.avatar}>
          {orgName.length > 0 ? orgName[0].toUpperCase() : ''}
        </Avatar>
      ) : (
        <></>
      )}
    </>
  );
}

OrgAvatar.propTypes = {
  orgName: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default OrgAvatar;
