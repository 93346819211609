import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import propsTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ModalContext } from '../../../context/ModalContext';
import { SnackbarContext } from '../../../context/SnackbarContext';
import {
  addMimeTypeToFiles,
  extractStudyMetadataFromPaths,
  UploadCancelError,
  extractDuplication,
  UploadService,
} from '../../../utils/upload';

import { checkFullAccess } from '../../../utils/checkAccessPerm';
import { useCheckOrgPerm } from '../../organization/api/getOrgUserPerm';
import {
  useUpdateWhenDelete,
  useUpdateWhenUpload,
} from '../api/fetchUpdateInfo';
import { checkItemsFullAccessAndAlert } from '../../../utils/upload/checkItemsFullAccessAndAlert';
import { deleteFolder, getTargetDataList } from '../../../api/rest';
import { isConferencePage } from './Conference/StorageConference';
import { useOrgDetail } from '../../organization/api/getOrgDetail';
import UploadConfirmationModal from './Conference/UploadConfirmationModal';
import { generateUniviewerUrl } from '../../univiewer/UniversalViewerContainer';

const UploadContext = React.createContext({});

const UploadProvider = props => {
  // Import Modal contexts
  const {
    openAnonymizeDialog, openConfirmDuplicateModal,
  } = useContext(ModalContext);

  const {
    setOpenSnackbar,
    setSnackbarMessage,
    setProgressValue,
    setOpenSnackbarCollapse,
    handleSnackbarCompleted,
  } = useContext(SnackbarContext);

  const updateWhenUpload = useUpdateWhenUpload();
  const updateWhenDelete = useUpdateWhenDelete();
  const {
    data: { memberPerm },
  } = useCheckOrgPerm();

  const [progress, setProgress] = React.useState(0);
  const resetProgress = () => {
    setProgress(progress => 0);
  };
  const completeProgress = () => setProgressValue(100);

  const isConference = isConferencePage();
  const [uploadedStudies, setUploadedStudies] = React.useState([]);

  const [openSummary, setOpenSummary] = React.useState(false);
  // useParams doesn't work inside a Context provider
  // https://stackoverflow.com/questions/70568571/why-doesnt-react-router-dom-useparams-hook-work-within-context
  // const { initialOrgId, parentUUID, folderUUID } = useParams();

  // Use regex instead to get the current org
  // Define a regular expression to extract org from the URL
  const orgRegex = /\/org\/([^\/]+)/;
  // Match the regex against the URL
  const match = window.location.href.match(orgRegex);

  // Extract the organization from the matched result
  const currentOrg = match ? match[1] : null;
  if (currentOrg) {
    console.log('UploadProvider org', currentOrg);
  }

  useEffect(() => {
    setProgressValue(progress);
  }, [progress, setProgressValue]);

  const handleOpenSummary = () => {
    setOpenSummary(true);
  };

  const handleCloseSummary = () => {
    setOpenSummary(false);
  };

  const uploadFiles = async (
    files,
    targetFolderUUID,
    targetOrgUUID = '',
    isOrgPerm = false
  ) => {
    try {
      resetProgress();
      setOpenSnackbar(true);
      setSnackbarMessage('アップロード準備中');
      console.log('uploadFiles UPLOAD UPLOAD ********');

      // mime type を追加
      const filesWithMime = await addMimeTypeToFiles(files);

      // studyの情報を抽出し、dicomとdicom以外のファイルに分ける
      const [
        studies,
        dcmFiles,
        notDcmFiles,
      ] = await extractStudyMetadataFromPaths(filesWithMime);

      // dicomが存在すれば匿名化・編集を行う画面を表示
      // modalをopenしてpromiseがresolveされるまで待機
      let newStudyData = [];
      let isAnonymize = false;
      if (studies.length > 0) {
        [newStudyData, isAnonymize] = await new Promise(function(
          resolve,
          reject
        ) {
          openAnonymizeDialog(studies, { resolve, reject });
        });
      }

      // upload先のデータ一覧取得
      const targetDataList = await getTargetDataList({
        targetFolderUUID,
        targetOrgUUID,
      });
      console.log('getTargetDataList UPLOAD UPLOAD ********', targetDataList);

      // 重複しているデータをtargetDataListから抽出
      const [
        duplicatedStudies,
        duplicatedFolders,
        duplicatedFiles,
      ] = extractDuplication(
        targetDataList,
        newStudyData,
        isAnonymize,
        notDcmFiles
      );

      // 重複対象がフルアクセス権限を持つかどうかを確認
      checkItemsFullAccessAndAlert([
        ...duplicatedFiles,
        ...duplicatedStudies,
        ...duplicatedFolders,
      ]);

      // 重複を確認し存在すれば置き換えるかキャンセルするか確認
      let duplicatedOptions = { studyReplace: false, folderReplace: false };
      if (
        duplicatedStudies.length > 0 ||
        duplicatedFolders.length > 0 ||
        duplicatedFiles.length > 0
      ) {
        duplicatedOptions = await new Promise(function(resolve, reject) {
          openConfirmDuplicateModal({ resolve, reject });
        });
        // "studyの置き換え(studyReplace)"を指定したらstudyを削除
        if (duplicatedOptions.studyReplace) {
          await Promise.all(
            duplicatedStudies.map(item => {
              deleteFolder(item.uuid);
            })
          );
          updateWhenDelete(duplicatedStudies);
        }
        // folderの重複は削除してからアップロード
        if (duplicatedOptions.folderReplace) {
          await Promise.all(
            duplicatedFolders.map(item => deleteFolder(item.uuid))
          );
          updateWhenDelete(duplicatedFolders);
        }
        // fileの重複は削除せずにそのままアップロード
      }

      // アップロード
      console.log('READY TO UPLOAD');
      setSnackbarMessage('アップロード中');
      setOpenSnackbarCollapse(true);
      const uploadService = new UploadService({
        files: filesWithMime,
        targetFolderUUID: targetFolderUUID,
        targetOrgUUID: targetOrgUUID,
        isOrgPerm: isOrgPerm,
        setProgress: setProgress,
      });
      const results = await uploadService.asyncAllUpload();

      // 完了通知
      handleSnackbarCompleted('アップロードが完了しました');
      console.log('UPLOAD COMPLETE');

      // Show summary for an upload from Conference page
      if ((isConference) && (studies) && (studies.length > 0)) {
        // TODO: aggregate server results and studies here
        console.log('UploadProvider studies', studies, 'results', results);
        // Open the summary window only if studies is not empty
        let studySummary = {};
        let modalities = new Set();
        // Assume that the first study matches the result from backend
        const study0 = studies[0];
        let study0uuid = '';
        const numSeries = study0.series.length;
        let numInstances = 0;
        for (const s of study0.series) {
          modalities.add(s.Modality);
          numInstances += s.instances.length;
        }
        for (var result of results) {
          if ((result !== undefined) && (result.name) && (result.uuid)) {

            const urlData = {
                name: result.name,
                uuid: result.uuid,
                org: currentOrg,
            }
            // const univiewerUrl = generateUniviewerUrl(urlData);
            result.url = generateUniviewerUrl(urlData, true);
            // Add to study dict
            studySummary[result.uuid] = result;
            study0uuid = result.uuid;
          }
        }

        // Set additional data for studySummary
        studySummary[study0uuid].modalities = modalities;
        studySummary[study0uuid].numSeries = numSeries;
        studySummary[study0uuid].numInstances = numInstances;

        console.log('studySummary', studySummary, 'study0uuid', study0uuid);

        // Display study results after uploading
        handleOpenSummary();
        setUploadedStudies(studySummary);
      }

    } catch (err) {
      console.log('Upload Error', err);
      let message = '';
      if (err instanceof UploadCancelError) {
        // キャンセル時
        message = 'アップロードをキャンセルしました';
        toast.info(message);
      } else if (err instanceof DOMException) {
        message = 'アップロードが失敗しました。';
        toast.error(
          'アップロードが失敗しました。ファイルサイズが大きすぎる可能性があります。'
        );
      } else if (err.response && err.response.status == 507) {
        message = 'アップロードが失敗しました。';
        toast.error('アップロードが失敗しました。容量制限を超えています');
      }
      // Handle snackbar completion
      handleSnackbarCompleted(message);
    }
  };

  const makeOnDrop = (uploadFolder, isFetch = true) => {
    const onDrop = async files => {
      if (checkFullAccess(uploadFolder.perm)) {
        await uploadFiles(files, uploadFolder.uuid, '', false);
        if (isFetch && window.location.pathname.includes(uploadFolder.uuid)) {
          await updateWhenUpload(uploadFolder);
        }
      } else {
        alert('アップロードにはフルアクセス権限が必要です');
      }
    };
    return onDrop;
  };

  const makeOnDropRoot = (targetOrgUUID = '', rootPermName, isFetch = true) => {
    const onDrop = async files => {
      if (memberPerm) {
        if (rootPermName === 'share') {
          alert('データはプライベート内にアップロードされます。');
        }
        const isOrgPerm = rootPermName === 'org' ? true : false;
        await uploadFiles(files, '', targetOrgUUID, isOrgPerm);
        if (isFetch) {
          await updateWhenUpload();
        }
      } else {
        alert('アップロードには組織に参加する必要があります。');
      }
    };
    return onDrop;
  };

  return (
    <div>
      <UploadConfirmationModal
        open={openSummary}
        handleClose={handleCloseSummary}
        uploadedStudies={uploadedStudies}
      />

      <UploadContext.Provider
        value={{
          progress,
          setProgress,
          resetProgress,
          completeProgress,
          makeOnDrop,
          makeOnDropRoot,
          uploadFiles,
        }}
      >
        {props.children}
      </UploadContext.Provider>
    </div>
  );
};

UploadProvider.propsTypes = {
  props: propsTypes.node.isRequired,
};

export { UploadContext, UploadProvider };
